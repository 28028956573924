import styled from "styled-components";

export const V4AboutPageWrapper = styled.div`
    padding-top: 25px;
    min-height: 300px;
`

export const V4AboutPageContentModeWrapper = styled.div<{ $isPrivateView: boolean }>`
    cursor:${({ $isPrivateView }) => $isPrivateView ? "pointer" : undefined};
    border-radius:  ${({ $isPrivateView }) => $isPrivateView ? "10px" : undefined};
    padding: ${({ $isPrivateView }) => $isPrivateView ? "10px" : undefined};

    > * {
        pointer-events: none;
    }

    &:hover {
        outline: ${({ $isPrivateView }) => $isPrivateView ? "1.5px dashed rgba(110, 118, 135, 0.3)" : undefined};
    }
`;