import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import { getV3ContentDimension } from "../../utils/getV3ContentDimension";
import { ProfileView, UserProfileStyle } from "authory-api-types/dist/enums";
import { PROFILE_HERO_PAD } from "../../components/ProfileHero";

export const PCFWrapper = styled.div<{ $sideMargins: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-start;
    }

    > div {
        margin-bottom: 18px;

        ${media.tablet} {
            margin-bottom: 0;
        }

        &:first-child {
            width: 100%;

            ${media.tablet} {
                margin-right: ${({ $sideMargins }) => $sideMargins ? "20px;" : undefined}
            }
        }

        &:nth-child(2) {
            flex-shrink: 0;
            width: 100%;

            ${media.tablet} {
                margin-left: ${({ $sideMargins }) => $sideMargins ? "20px;" : undefined}
            }
            
            ${media.tablet} {
               width:  auto;
            }
        }
    }
`;

export const PublicProfileSearchItemsPad = styled.div`
    padding: 0 18px;
`;


export const PublicProfileSearchItemsContentSizer = styled.div<{ $viewType: ProfileView, $profileStyle: UserProfileStyle, $isAboutPage?: boolean }>`
    margin: 0 auto;
    max-width: ${({ $viewType, $isAboutPage }) => `${getV3ContentDimension($viewType, !!$isAboutPage) + PROFILE_HERO_PAD}px`};
    width: 100%;
    position: relative;
    background-color: white;
    padding-top: 25px;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: 18px 40px 0;
        margin-top: ${({ $isAboutPage }) => $isAboutPage ? "-40px" : "-60px"};
        border-radius: 6px;
    }
`;

export const V3PublicPageTeaser = styled.div`
    padding: 8px 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
`;

export const V4EditCoverPos = styled.div`
    position: absolute;
    right: 5px;
    top: -30px;
`;