import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor, MeasuringConfiguration, MeasuringStrategy, PointerSensor, UniqueIdentifier, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { flushSync } from "react-dom";
import sanitizeHtml from 'sanitize-html';
import { throttle } from "lodash";
import { CSS } from '@dnd-kit/utilities';
import SimpleBarCore from "simplebar-core";
import ContentEditable from "react-contenteditable";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { AddItShortcutBackground, AddColShortcutPos, ECLeftSideCol, ECWrapper, EditableItemWrapper, EditableSortableItemsWrapper, SimpleBarWithFade, ReorderWrapper, ScrollShortcutPos, HomeWrapper } from "./EditableSortableItems.styles"
import { V3Dropdown } from "../Dropdown"
import { V3IconEdit, V3IconSettings, V3IconGrab, V3IconRemoveFromProfile, V3IconCheck, V3IconBin } from "../Icons"
import { DropdownOptionCTA } from "../DropdownOptionCTA"
import { V3BlueRoundPlus, V3BlueRoundPlusColor } from "../ReusableStyledComponents/V3BlueRoundPlus";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useCustomScrollShadows } from "../../hooks/customScrollShadows";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import { V3TertiaryButton } from "../Button";
import { V3Tooltip } from "../Tooltip";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";
import { isMainDomain } from "../../utils/domainRouting";
import { RequestHost } from "../../types/headers";
import { DomainContext } from "../../context/domainContext";
import { V3TertiaryCTAColor } from "../Button/V3TertiaryCTA.types";
import { theme } from "../../theme/theme";
import { SORTABLE_HOME_IDENTIFIER } from "../ProfileHero/V4ProfileHero.utils";
import { V3ConfirmationModal } from "../V3ConfirmationModal";
import { V3ModalIconSegmentVariant } from "../ReusableStyledComponents/ModalIconSegment";
import { EditableItem } from "./EditableSortableItems.types";
import { NotificationInlineLink } from "../ReusableStyledComponents/NotificationInlineLink";

const scrollSimpleBar = (target: string) => () => setTimeout(() => {
    const addNewElem = document.querySelector(`[data-is-add-element="true-${target}"]`);

    if (addNewElem) {
        addNewElem.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
}, 200);

const moveCursorToEnd = (contentEle: any) => {
    try {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStart(contentEle, contentEle.childNodes.length);
        range.collapse(true);
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
        }
    } catch { }
};

const sanitizeConf = {
    allowedTags: [],
    allowedAttributes: {},
};

export enum EditableSortableTheme {
    Light = "Light",
    Dark = "Dark"
}

interface EditableItProps {
    item?: EditableItem,
    editMode: boolean,
    dragMode?: boolean,
    onSelectDrag: () => void,
    activeDrag?: boolean,
    isOverlay?: boolean,
    index?: number,
    activeId?: UniqueIdentifier | null,
    orientation?: "before" | "after",
    activeCol: string,
    onItemSelect: (slug: string) => void,
    onCollectionSubmitName?: (name: string, slug?: string) => Promise<any>,
    onCollectionRemoveFromProfile?: (slug: string) => void,
    isEditing?: boolean,
    setEditingComponentID?: (id: string) => void,
    onCollectionUpdateRules?: (slug: string) => void,
    scrolling?: boolean,
    onOpenChange?: (open: boolean) => void,
    addColSettingsActive?: boolean,
    totalItemsNumber: number,
    addText?: string,
    itemType: EditableSortableType,
    colorVariant: EditableSortableTheme
}

export const EditableIt = ({ item, editMode, dragMode, colorVariant, onSelectDrag, activeDrag = false, isOverlay = false, activeId, orientation, activeCol, onItemSelect, onCollectionSubmitName, onCollectionRemoveFromProfile, isEditing = false, setEditingComponentID, onCollectionUpdateRules, scrolling, onOpenChange, addColSettingsActive, totalItemsNumber, addText, itemType }: EditableItProps) => {
    // Current Domain
    const requestHostHeader: RequestHost = useContext(DomainContext) ?? RequestHost.MAINDOMAIN;
    const isCollectionType = itemType === EditableSortableType.Collection;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [renameModalOpen, setRenameModalOpen] = useState(false);

    const { attributes, listeners, isDragging, isSorting, over, setNodeRef, transform, transition } = useSortable({
        id: item?.id || "",
        animateLayoutChanges: () => true,
    });

    const collectionLabelRef = useRef(item?.label || "");
    const inputRef = useRef(null);
    const isAddElement = !item;
    const isAddElementEditing = !item && isEditing;

    useEffect(() => {
        if (isEditing) {
            onOpenChange && onOpenChange(false);
            setTimeout(() => {
                moveCursorToEnd(inputRef.current);
            }, 200);
        }
    }, [isEditing]);

    const isOver = over?.id === item?.id && over?.id !== activeId;

    const submitName = () => {
        if (collectionLabelRef.current.trim().length) {
            onCollectionSubmitName && onCollectionSubmitName(collectionLabelRef.current, item?.id);
        }

        if (!item?.id) collectionLabelRef.current = "";

        setEditingComponentID && setEditingComponentID("");
    }

    const onRenameStart = () => {
        flushSync(() => {
            item && setEditingComponentID && setEditingComponentID(item.id);
        })

        moveCursorToEnd(inputRef.current);
    }

    return <div
        ref={setNodeRef}
        id={item?.id}
        style={{
            background: isDragging ? isCollectionType ? "white" : "transparent" : undefined,
            zIndex: isDragging ? 1 : undefined,
            transition,
            borderRadius: 6,
            transform: isSorting ? undefined : CSS.Translate.toString(transform),
            cursor: isEditing ? "text" : isOverlay ? "grabbing" : dragMode ? "grab" : "initial",
            touchAction: dragMode || isEditing ? "none" : undefined,
        }}
        {...attributes}
        {...listeners}
        // this selector is used so that the empty screen component can simulate a click
        data-item-add={totalItemsNumber === 0 && !item && isCollectionType ? "add-element" : undefined}
    >
        <EditableItemWrapper
            $alternateHover={!isCollectionType}
            $activeDrag={activeDrag}
            $dragMode={dragMode || false}
            $isOverlay={isOverlay}
            $isEditingName={isEditing}
            $editMode={editMode}
            $isAddElement={isAddElementEditing}
            data-is-add-element={isAddElement ? `true-${itemType}` : "false"}
            $orientation={isOver ? orientation : undefined}
            $colorVariant={colorVariant}
        >
            <OptionalNextLink
                href={item && item.href ? item.href : undefined}
                useNextRouter={isMainDomain(requestHostHeader) || editMode}
                useProvidedURLInFul={!isMainDomain(requestHostHeader) && !editMode}
                isMainDomain={isMainDomain(requestHostHeader)}
            >
                <ECLeftSideCol
                    $alternateHover={!isCollectionType}
                    $dragMode={dragMode}
                    $editMode={editMode && !dragMode}
                    $active={activeCol === item?.id}
                    $isEditingName={isEditing}
                    onClick={!dragMode && !isEditing ? () => {
                        onItemSelect(item?.id || "");
                    } : undefined}
                    $isAddElement={isAddElement}
                    $colorVariant={colorVariant}
                    $settingsActive={addColSettingsActive}
                    $hasSettingsHidden={item?.hideSettings}
                >
                    {editMode && isEditing ? <div>
                        <ContentEditable
                            innerRef={inputRef}
                            html={collectionLabelRef.current}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                                const key = e.charCode || e.keyCode || 0;

                                if (key === 13) {
                                    submitName();
                                }
                            }}
                            onBlur={() => {
                                submitName();
                            }}
                            onChange={(evt) => {
                                collectionLabelRef.current = sanitizeHtml(evt.target.value, sanitizeConf);
                            }}
                            disabled={!editMode}
                            //@ts-ignore
                            placeholder={isCollectionType ? "Enter collection name..." : "Enter page name..."}
                        />
                    </div> : item ? <>
                        <ContentEditable onChange={() => { }} disabled innerRef={inputRef} html={collectionLabelRef.current} />
                    </> : <V3TertiaryButton
                        onClick={() => { }}
                        icon={<V3BlueRoundPlus colorVariant={colorVariant === EditableSortableTheme.Dark ? V3BlueRoundPlusColor.primary : V3BlueRoundPlusColor.tertiary} />}
                        text={addText}
                        ctaColor={colorVariant === EditableSortableTheme.Dark ? V3TertiaryCTAColor.color : V3TertiaryCTAColor.white}
                    />}
                </ECLeftSideCol>
            </OptionalNextLink>
            <div>
                {
                    editMode && isAddElement && isEditing && <ECLeftSideCol $alternateHover={!isCollectionType} $colorVariant={colorVariant} $editMode={editMode} $active={false} $isEditingName={false} $isSettingsIcon $isCheckIcon>
                        <V3IconCheck strokeWidth={2} stroke={colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"} />
                    </ECLeftSideCol>
                }
                {editMode && item && !item.hideSettings && <>
                    {
                        isEditing ? <ECLeftSideCol $alternateHover={!isCollectionType} $colorVariant={colorVariant} $editMode={editMode} $active={false} $isEditingName={false} $isSettingsIcon $isCheckIcon>
                            <V3IconCheck strokeWidth={2} stroke={colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"} />
                        </ECLeftSideCol >
                            : dragMode ?
                                <ECLeftSideCol $alternateHover={!isCollectionType} $colorVariant={colorVariant} $editMode={false} $active={false} $isEditingName={false}>
                                    <V3IconGrab size={20} />
                                </ECLeftSideCol>
                                :
                                <V3Dropdown
                                    title="Settings"
                                    shiftPadding={80}
                                    placement={isCollectionType ? "bottom-end" : "bottom-start"}
                                    strategy="fixed"
                                    customOffset={{
                                        crossAxis: 2,
                                        mainAxis: 5
                                    }}
                                    onShowChange={onOpenChange}
                                    renderReferenceElement={(active, setActive) => {
                                        const RefElem = () => <ECLeftSideCol $alternateHover={!isCollectionType} $colorVariant={colorVariant} $editMode={editMode} $active={false} $isEditingName={false} $isSettingsIcon $settingsActive={active}>
                                            <V3IconSettings size={19} strokeWidth="1.3" />
                                        </ECLeftSideCol>;

                                        if (scrolling && active) setActive(false);

                                        return active || scrolling ? <RefElem /> : <V3Tooltip
                                            delay={0}
                                            referenceContent={<RefElem />}
                                            tooltipContent={isCollectionType ? "Collection settings" : "Page settings"}

                                        />
                                    }}
                                    renderPopperElement={(_, setActive) => {
                                        setEditingComponentID && setEditingComponentID("");

                                        return <>
                                            <DropdownOptionCTA
                                                icon={<V3IconEdit />}
                                                label={"Rename"}
                                                onClick={() => {
                                                    setActive(false);

                                                    if (isCollectionType) {
                                                        onRenameStart();
                                                    } else {
                                                        setRenameModalOpen(true)
                                                    }
                                                }}
                                            />
                                            {
                                                totalItemsNumber > 1 && <DropdownOptionCTA
                                                    icon={<V3IconGrab />}
                                                    label={isCollectionType ? "Reorder collections" : "Reoder pages"}
                                                    onClick={onSelectDrag}
                                                />
                                            }
                                            {onCollectionUpdateRules && <DropdownOptionCTA
                                                icon={<V3IconSettings />}
                                                label={"Filters & Auto-updating"}
                                                onClick={() => {
                                                    setActive(false);
                                                    onCollectionUpdateRules(item.id);
                                                }}
                                            />}
                                            <DropdownOptionCTA
                                                icon={isCollectionType ? <V3IconRemoveFromProfile stroke="#ABB0BA" /> : <V3IconBin stroke={"#ABB0BA"} />}
                                                label={isCollectionType ? "Remove from portfolio" : "Delete page"}
                                                onClick={() => {
                                                    setActive(false);
                                                    if (isCollectionType) {
                                                        onCollectionRemoveFromProfile && onCollectionRemoveFromProfile(item.id);
                                                    } else {
                                                        setDeleteModalOpen(true);
                                                    }
                                                }}
                                            />
                                        </>
                                    }}
                                />
                    }
                </>}
            </div>
        </EditableItemWrapper>
        {
            deleteModalOpen && <V3ConfirmationModal
                onSubmit={() => {
                    item && onCollectionRemoveFromProfile && onCollectionRemoveFromProfile(item.id);
                    setDeleteModalOpen(false);
                }}
                setModalOpen={setDeleteModalOpen}
                modalOpen={deleteModalOpen}
                title={`Delete page "${item?.label}" ?`}
                btnLabel={"Delete"}
                useIcon
                variant={V3ModalIconSegmentVariant.error}
                description={"Are you sure you want to delete this page? This action cannot be undone."}
            />
        }
        {
            renameModalOpen && <V3ConfirmationModal
                onSubmit={() => {
                    setRenameModalOpen(false);
                    onRenameStart();
                }}
                setModalOpen={setRenameModalOpen}
                modalOpen={renameModalOpen}
                title={"Rename page"}
                btnLabel={"Continue"}
                useIcon
                variant={V3ModalIconSegmentVariant.warning}
                description={"If you change the name of this page, then the URL will change, too, and any previously shared URL will be broken."}
            />
        }
    </div >
}

interface MainElemProps {
    its: EditableItem[],
    editMode: boolean,
    dragMode: boolean,
    onSelectDrag: () => void,
    activeIndex: number,
    activeId: UniqueIdentifier | null,
    activeCol: string,
    onItemSelect: (slug: string) => void,
    onCollectionSubmitName?: (name: string, slug?: string) => Promise<any>,
    onCollectionRemoveFromProfile?: (slug: string) => void,
    editingComponentID?: string,
    setEditingComponentID?: (id: string) => void,
    onCollectionUpdateRules?: (slug: string) => void,
    handleDragEnd?: ({ over }: DragEndEvent) => void,
    scrolling?: boolean,
    isDropdownOpen?: boolean,
    setIsDropdownOpen?: (open: boolean) => void,
    mainAddItem: JSX.Element,
    itemType: EditableSortableType,
    colorVariant: EditableSortableTheme
}

const MainElem = ({
    its,
    editMode,
    dragMode,
    onSelectDrag,
    activeIndex,
    activeId,
    activeCol,
    onItemSelect,
    onCollectionSubmitName,
    onCollectionRemoveFromProfile,
    editingComponentID,
    setEditingComponentID,
    onCollectionUpdateRules,
    scrolling,
    isDropdownOpen,
    setIsDropdownOpen,
    mainAddItem,
    itemType,
    colorVariant,
}: MainElemProps) => {
    const isCollectionType = itemType === EditableSortableType.Collection;
    return <EditableSortableItemsWrapper
        $editMode={editMode}
        $dragMode={dragMode}
        $editingComponentID={editingComponentID}
        $isDropdownOpen={isDropdownOpen}
        data-intercom-target={isCollectionType ? "ProfileCollectionsBar" : undefined}
        $colorVariant={colorVariant}
        $alternateHover={!isCollectionType}
    >
        {
            its.map((c, index) => <EditableIt
                item={c}
                editMode={editMode}
                dragMode={dragMode}
                onSelectDrag={onSelectDrag}
                activeDrag={activeIndex === index}
                activeId={activeId}
                orientation={index > activeIndex ? "after" : "before"}
                activeCol={activeCol}
                onItemSelect={onItemSelect}
                onCollectionSubmitName={onCollectionSubmitName}
                key={c.id}
                onCollectionRemoveFromProfile={onCollectionRemoveFromProfile}
                setEditingComponentID={setEditingComponentID}
                isEditing={editingComponentID === c.id}
                onCollectionUpdateRules={onCollectionUpdateRules}
                scrolling={scrolling}
                onOpenChange={setIsDropdownOpen}
                totalItemsNumber={its.length}
                itemType={itemType}
                colorVariant={colorVariant}
            />)
        }
        {
            !dragMode && editMode && <>
                {mainAddItem}
            </>
        }
    </EditableSortableItemsWrapper>
};

type AddItemFunction = (
    scrolling: boolean,
    onNew: () => void,
    onOpenChange: (open: boolean) => void,
    simplebarScroll: () => void,
) => JSX.Element;

type AddParameters<
    TFunction extends (...args: any) => any,
    TParameters extends [...args: any]
> = (
    ...args: [...Parameters<TFunction>, ...TParameters]
) => ReturnType<TFunction>;

export enum EditableSortableType {
    Collection = "Collection",
    AboutPages = "AboutPages",
}

interface EditableSortableItemsProps {
    its: EditableItem[],
    editMode?: boolean,
    activeCol: string,
    onItemSelect: (slug: string) => void,
    onCollectionStopDrag?: (data: Record<string, number>) => void,
    onCollectionSubmitName?: (name: string, slug?: string) => Promise<any>,
    onCollectionRemoveFromProfile?: (slug: string) => void,
    onCollectionUpdateRules?: (slug: string) => void,
    renderShortcutAddItem: AddItemFunction
    renderMainAddItem: AddParameters<AddItemFunction, [setEditingComponentID: Dispatch<SetStateAction<string>>, isEditing: boolean, itemsLenngth: number]>
    itemType?: EditableSortableType,
    colorVariant?: EditableSortableTheme,
    scrollCustomColor?: string,
}

export const EditableSortableItems = ({ its, editMode = false, activeCol, itemType = EditableSortableType.Collection, colorVariant = EditableSortableTheme.Dark, scrollCustomColor, onItemSelect, onCollectionStopDrag, onCollectionSubmitName, onCollectionRemoveFromProfile, onCollectionUpdateRules, renderShortcutAddItem, renderMainAddItem }: EditableSortableItemsProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [smallAddDropdownActive, setSmallAddDropdownActive] = useState(false);

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [items, setItems] = useState(its);
    const activeIndex = activeId ? items.findIndex(coll => coll.id === activeId) : -1;
    const [editingComponentID, setEditingComponentID] = useState("");
    const [scrolling, setScrolling] = useState(false);

    const size = useWindowSize();

    const [hideAddColShort, setShowAddColShortcut] = useState(true);
    const [elem, setElem] = useState<HTMLDivElement>();
    const scrollbarRef = useRef<SimpleBarCore>();
    const [dragMode, setDragMode] = useState(false);
    const wrapperRef = useRef(null);
    const { onScroll, ref, shadowStates } = useCustomScrollShadows();

    const isCollectionType = itemType === EditableSortableType.Collection;

    const shadowClasses = `${shadowStates.leftShadow ? "left-shadow" : ""} ${shadowStates.rightShadow && (hideAddColShort || !editMode) ? "right-shadow" : ""}`;

    const checkShowAddCol = (el: HTMLDivElement) => {
        const shouldHideAddColShort = Math.ceil(el.offsetWidth + el.scrollLeft) >= el.scrollWidth - (!editMode ? 0 : dragMode ? 10 : 100);
        setShowAddColShortcut(shouldHideAddColShort);
    }

    useEffect(() => {
        setItems(its);
    }, [its]);

    useOutsideAlerter(wrapperRef, () => {
        if (setDragMode) {
            setDragMode(false);

            setItems(itsTemp => {

                onCollectionStopDrag && onCollectionStopDrag(itsTemp.reduce((acc, curr, index) => {
                    return { ...acc, [curr.id]: index + 1 }
                }, {}))

                return itsTemp;
            });
        }
    }, [wrapperRef, onCollectionStopDrag]);

    useEffect(() => {
        if (!elem) return;
        checkShowAddCol(elem as unknown as HTMLDivElement)
    }, [size.width, size.height, editMode, its]);

    function handleDragStart({ active }: DragStartEvent) {
        setActiveId(active.id);
    }

    function handleDragEnd({ over }: DragEndEvent) {
        if (over) {
            const overIndex = items.findIndex(coll => coll.id === over.id);

            if (activeIndex !== overIndex) {
                const newIndex = overIndex;

                setItems((items) => arrayMove(items, activeIndex, newIndex));
            }
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    const measuring: MeasuringConfiguration = {
        droppable: {
            strategy: MeasuringStrategy.Always,
        },
    };

    const throttledSetScrollFalse = throttle(() => setScrolling(false), 100, { leading: false });

    const hasHomeElement = items[0]?.id === SORTABLE_HOME_IDENTIFIER;
    const homeElement = hasHomeElement ? items[0] : undefined;
    const itemsToRender = hasHomeElement ? items.slice(1) : items;

    const HomeElement = homeElement ? () => <EditableIt
        item={homeElement}
        editMode={editMode}
        onSelectDrag={() => { }}
        activeId={activeId}
        activeCol={activeCol}
        onItemSelect={onItemSelect}
        onCollectionSubmitName={onCollectionSubmitName}
        key={homeElement.id}
        onCollectionRemoveFromProfile={onCollectionRemoveFromProfile}
        setEditingComponentID={setEditingComponentID}
        onCollectionUpdateRules={onCollectionUpdateRules}
        scrolling={scrolling}
        onOpenChange={setIsDropdownOpen}
        totalItemsNumber={its.length}
        itemType={itemType}
        colorVariant={colorVariant}
    /> : () => <></>;

    return <ECWrapper>
        <div>
            <SimpleBarWithFade
                $scrollCustomColor={scrollCustomColor}
                $colorVariant={colorVariant}
                onMouseEnter={() => {
                    if (scrollbarRef.current && 'options' in scrollbarRef.current !== undefined) {
                        scrollbarRef.current.options.autoHide = false;
                    }
                }}
                onMouseLeave={() => {
                    if (scrollbarRef.current && 'options' in scrollbarRef.current !== undefined) {
                        scrollbarRef.current.options.autoHide = true;
                    }
                }}
                scrollableNodeProps={{
                    onScroll: (evt: any) => {
                        const el = evt.target as HTMLDivElement;
                        checkShowAddCol(el);
                        onScroll(evt);
                        setScrolling(true);
                        throttledSetScrollFalse.cancel();
                        throttledSetScrollFalse();
                    },
                    ref: (e: any) => {
                        setElem(e)
                        ref(e);
                    }
                }}
                className={shadowClasses}
                //@ts-ignore
                ref={scrollbarRef}
            >
                <div style={{ display: 'flex' }} data-intercom-target={!isCollectionType ? "AboutPages" : undefined}>
                    {
                        homeElement && <>
                            {editMode ? <V3Tooltip
                                referenceContent={<HomeWrapper $colorVariant={colorVariant} $editMode={editMode} $editingComponentID={editingComponentID}>
                                    <HomeElement />
                                </HomeWrapper>}
                                tooltipMaxWidth={360}
                                tooltipContent={<>
                                    "Home" is the label of the main page of your portfolio and it can't be edited. You can add more pages by clicking "Create page" on the right. <NotificationInlineLink href="https://www.youtube.com/watch?v=qkEW5NLerWw&ab_channel=Authory" target="_blank">Learn more about Pages</NotificationInlineLink>
                                </>}
                            /> : <HomeElement />}
                        </>
                    }
                    <div>
                        {
                            dragMode ? <DndContext
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                                onDragCancel={handleDragCancel}
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                measuring={measuring}
                            >
                                <SortableContext items={itemsToRender.map(it => it.id)}>
                                    <ReorderWrapper $colorVariant={colorVariant}>
                                        <div ref={wrapperRef}>
                                            <MainElem
                                                its={itemsToRender}
                                                editMode={editMode}
                                                onSelectDrag={() => setDragMode(true)}
                                                activeIndex={activeIndex}
                                                activeId={activeId}
                                                activeCol={activeCol}
                                                dragMode
                                                onItemSelect={() => { }}
                                                onCollectionSubmitName={async () => { }}
                                                handleDragEnd={handleDragEnd}
                                                mainAddItem={<></>}
                                                itemType={itemType}
                                                colorVariant={colorVariant}
                                            />
                                        </div>
                                        <div>
                                            {dragMode && editMode && <V3IconCheck strokeWidth={2} stroke={colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"} />}
                                        </div>
                                    </ReorderWrapper>
                                </SortableContext>
                                <DragOverlay>
                                    {activeId ? (
                                        <>
                                            <EditableIt
                                                item={itemsToRender[activeIndex]}
                                                editMode={editMode}
                                                dragMode={dragMode}
                                                onSelectDrag={() => setDragMode(true)}
                                                isOverlay
                                                activeCol={""}
                                                onItemSelect={() => { }}
                                                totalItemsNumber={0}
                                                itemType={itemType}
                                                colorVariant={colorVariant}
                                            />
                                        </>
                                    ) : null}
                                </DragOverlay>
                            </DndContext> : <MainElem
                                its={itemsToRender}
                                editMode={editMode}
                                onSelectDrag={() => setDragMode(true)}
                                activeIndex={activeIndex}
                                activeId={activeId}
                                activeCol={activeCol}
                                dragMode={false}
                                onItemSelect={onItemSelect}
                                onCollectionSubmitName={onCollectionSubmitName}
                                onCollectionRemoveFromProfile={onCollectionRemoveFromProfile}
                                editingComponentID={editingComponentID}
                                setEditingComponentID={setEditingComponentID}
                                onCollectionUpdateRules={onCollectionUpdateRules}
                                scrolling={scrolling}
                                isDropdownOpen={isDropdownOpen}
                                setIsDropdownOpen={setIsDropdownOpen}
                                itemType={itemType}
                                mainAddItem={renderMainAddItem(
                                    scrolling,
                                    () => setEditingComponentID("add"),
                                    (open) => {
                                        setSmallAddDropdownActive(open);
                                        setIsDropdownOpen(open);
                                    },
                                    scrollSimpleBar(itemType),
                                    setEditingComponentID,
                                    editingComponentID === "add",
                                    its.length
                                )}
                                colorVariant={colorVariant}
                            />
                        }
                    </div>
                </div>
            </SimpleBarWithFade>
        </div>
        {
            !hideAddColShort && <>
                {!editMode ? <>
                    {
                        isCollectionType && <ScrollShortcutPos $colorVariant={colorVariant} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="13 17 18 12 13 7" />
                                <polyline points="6 17 11 12 6 7" />
                            </svg>
                        </ScrollShortcutPos>
                    }
                </> : <AddColShortcutPos $colorVariant={colorVariant} $scrollCustomColor={scrollCustomColor}>
                    {
                        dragMode ? <AddItShortcutBackground $colorVariant={colorVariant} ><V3IconCheck strokeWidth={2} stroke={colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"} /></AddItShortcutBackground> : <V3Tooltip
                            delay={0}
                            referenceContent={<AddItShortcutBackground $active={smallAddDropdownActive} $colorVariant={colorVariant}>
                                {renderShortcutAddItem(
                                    scrolling,
                                    () => setEditingComponentID("add"),
                                    (open) => {
                                        setSmallAddDropdownActive(open);
                                        setIsDropdownOpen(open);
                                    },
                                    scrollSimpleBar(itemType)
                                )}
                            </AddItShortcutBackground>}
                            tooltipContent={isCollectionType ? "Add collection" : "Add page"}
                        />
                    }
                </AddColShortcutPos>}
            </>
        }
    </ECWrapper >
}