import { AuthenticatedUserResponse, CertificateResponse, UserResponse } from "authory-api-types/dist/types";
import { ContactUserFormik, V3ContactUser } from "../V3ContactUser";
import { V4PHAuthorMetaWrapper, V4PHContentWrapper, V4PHProfileAvatarImage, V4PHProfileAvatarWrapper, V4PHImageContainer, V4PHTopBar, ProfileContentEditable, MobileSocialsWrapper, V4PHContentMainWrapper, ProfileSocialsContainer, CertificateModalLink, CertificateProfileModalItem, CertificatesItemsWrapper, ImageAvatar, ModalMetadata, V3ProfileName, V4PHMainWrapper, V4HealineCertificateWrapper, V3SeeMore, LegacyProfileElem, AboutPageTitleWrapper } from "./V4ProfileHero.styles";
import { getCoverInfo } from "../../utils/getCoverInfo";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ActionButtonType, ProfileView, UserCoverStyle, UserProfileStyle } from "authory-api-types/dist/enums";
import { V3HeadlineH1, V3Paragraph } from "../Typography";
import { EditableUIElement } from "../EditableUIElement";
import { V3IconCertificates, V3IconUpload } from "../Icons";
import { SORTABLE_HOME_IDENTIFIER, getV4ColorVariant } from "./V4ProfileHero.utils";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3ProfileContact, V3ProfileSocials } from "./V3ProfileSocials";
import { V3ProfileHeroColorVariants } from "./V3ProfileHero.types";
import { theme } from "../../theme/theme";
import { V3ModalBase } from "../V3ModalBase";
import { V3ProfileAvatar } from "../ProfileAvatar";
import { getAuthorName, getDisplayName } from "../../utils/getDisplayName";
import { UnstyledLink } from "../ReusableStyledComponents/UnstyledLink";
import { CertificateSVG } from "../../pagelayouts/settings/certificates";
import { V3CaptionGrey48 } from "../ReusableStyledComponents/V3CaptionGrey48";
import { getCustomDomainURL, isMainDomain } from "../../utils/domainRouting";
import { RequestHost } from "../../types/headers";
import { DomainContext, HostContext } from "../../context/domainContext";
import sanitizeHtml from "sanitize-html";
import { UpdateProfileFormik } from "../V3EditProfilemodal";
import { throttle } from "lodash";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { brToParagraph, paragraphToBr } from "../../utils/editableContentTransforms";
import { SidebarLayoutShowDesktop, SidebarLayoutShowMobile } from "../../layout/SideBarLayout";
import { truncate } from "../../utils/article-text-utils";
import { V3Tooltip } from "../Tooltip";
import { decodeEntities } from "../../utils/decodeHTMLEntities";
import { EditableIt, EditableSortableItems, EditableSortableTheme, EditableSortableType } from "../EditableSortableItems";
import { APPLICATION_ROUTES } from "../../types/routes";
import { V3BlueRoundPlus } from "../ReusableStyledComponents/V3BlueRoundPlus";
import { V3AuthoryLogo } from "../AuthoryLogo";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";
import { useWindowSize } from "../../hooks/useWindowSize";
import { AboutPagesMobileMenu } from "./AboutPagesMobileMenu";
import { EditableItem } from "../EditableSortableItems/EditableSortableItems.types";
import React from "react";

const sanitizeConf: sanitizeHtml.IOptions = {
    allowedTags: ['br'],
    allowedAttributes: {},
};

const SHORT_TEXT_LENGTH = 140;

interface V4ProfileHeroProps {
    author: AuthenticatedUserResponse | UserResponse,
    onContactUserSubmit: (data: ContactUserFormik) => Promise<void>,
    editMode?: boolean,
    certificates?: CertificateResponse[],
    initialContactOpen?: boolean,
    onSocialsEditableClick?: () => void,
    onContactEditableClick?: () => void,
    onPIEditableClick?: () => void,
    onEditProfileModalSubmit?: (data: UpdateProfileFormik) => void,
    openSubscribeModal: () => void,
    legacyEditingExperience: boolean,
    onAboutPageSubmitName?: (name: string, slug?: string) => Promise<any>,
    onAboutPageStopDrag?: (data: Record<string, number>) => void,
    onAboutPageDelete?: (id: string) => void,
    onAboutPageSelect: (id: string) => void,
    onAboutPageSubmitTitle?: (title: string, id: string) => Promise<any>,
    aboutPageId?: string,
    aboutPageTitle?: string,
    isAboutPage: boolean,
}

export const V4ProfileHero = ({
    author,
    editMode = false,
    certificates,
    initialContactOpen = false,
    onSocialsEditableClick,
    onContactEditableClick,
    onContactUserSubmit,
    onPIEditableClick,
    onEditProfileModalSubmit,
    openSubscribeModal,
    legacyEditingExperience,
    onAboutPageSubmitName,
    onAboutPageStopDrag,
    onAboutPageDelete,
    onAboutPageSelect,
    onAboutPageSubmitTitle,
    aboutPageId,
    aboutPageTitle,
    isAboutPage
}: V4ProfileHeroProps) => {

    // Current Domain
    const requestHostHeader: RequestHost = useContext(DomainContext) ?? RequestHost.MAINDOMAIN;
    const host = useContext(HostContext);

    // Details modal
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    // Certificates modal
    const [certmodalIsOpen, setcerModalIsOpen] = useState(false);

    const size = useWindowSize();

    // Contact modal
    const [contactModalOpen, setContactModalOpen] = useState(initialContactOpen);

    // Background and colors
    const { color, authorUploadedImage } = getCoverInfo(author);
    const colorVariant = getV4ColorVariant(color, author.coverStyle as UserCoverStyle);

    // Profile type
    const isCenteredProfile = author.profileStyle === UserProfileStyle.centered;

    const displayNameRef = useRef(paragraphToBr(author.displayName || ""));
    const bioRef = useRef(paragraphToBr(author.bio || ""));
    const aboutPageTitleRef = useRef(paragraphToBr(aboutPageTitle || ""));

    const userHasProfileImage = !!author.avatarUrl.length;
    const userHasProfileComponents = !!author.profileComponents.length;
    const userHasHighlightButton = author.actionButtonType !== ActionButtonType.Disabled;
    const userHasSubscribe = author.displayEmailSubscribe;

    const userHasComponents = userHasProfileComponents || userHasHighlightButton || userHasSubscribe;
    const userHasHighlightOrSubscribe = userHasHighlightButton || userHasSubscribe;

    const isColorNotWhite = author.coverStyle === UserCoverStyle.color && author.coverColor !== "#FFFFFF";
    const isColorWhite = author.coverStyle === UserCoverStyle.color && author.coverColor === "#FFFFFF";

    const userHasBio = !!bioRef.current.length;
    const userHasDisplayName = !!displayNameRef.current.length;

    const enforceMinHeight = !userHasProfileImage && !userHasBio && !userHasDisplayName && !editMode && (authorUploadedImage || isColorNotWhite);
    const hasSomeContent = userHasProfileImage || userHasBio || userHasDisplayName;

    // Bio clip business logic
    const splitText = author.bio.split("\n").filter(Boolean);
    const shortBio = splitText.length ? truncate(splitText[0], SHORT_TEXT_LENGTH) : "";
    const firstLineTooLong = splitText.length ? splitText[0].length > SHORT_TEXT_LENGTH : false;
    const showMore = splitText.length > 1 || firstLineTooLong;

    const onAboutPageTitleEnd = () => {
        onAboutPageSubmitTitle && aboutPageId && onAboutPageSubmitTitle(aboutPageTitleRef.current, aboutPageId);
    }

    const throttleOnAboutPageTitleEditEnd = throttle(onAboutPageTitleEnd, 1000, { leading: false });
    const callbackThrottleOnAboutPageTitleEditEnd = useCallback(throttleOnAboutPageTitleEditEnd, [aboutPageId]);

    const onAboutPageUpdate = () => {
        callbackThrottleOnAboutPageTitleEditEnd.cancel();
        callbackThrottleOnAboutPageTitleEditEnd();
        forceUpdate();
    }

    const onProfileEditEnd = () => {
        const data: UpdateProfileFormik = {
            description: decodeEntities(brToParagraph(bioRef.current)),
            displayName: decodeEntities(brToParagraph(displayNameRef.current)),
        }

        onEditProfileModalSubmit && onEditProfileModalSubmit(data);
    }

    const throttledOnProfileEditEnd = throttle(onProfileEditEnd, 1000, { leading: false });
    const callbackThrottledOnProfileEditEnd = useCallback(throttledOnProfileEditEnd, []);

    const forceUpdate = useForceUpdate();

    const onContentUpdate = () => {
        callbackThrottledOnProfileEditEnd.cancel();
        callbackThrottledOnProfileEditEnd();
        forceUpdate();
    }

    useEffect(() => {
        aboutPageTitleRef.current = aboutPageTitle || "";
        forceUpdate();
    }, [aboutPageTitle, aboutPageId]);

    useEffect(() => {
        if (!editMode && !!author.displayName) {
            displayNameRef.current = author.displayName;
        }
    }, [author.displayName]);

    useEffect(() => {
        if (!editMode && !!author.bio) {
            bioRef.current = sanitizeHtml(paragraphToBr(author.bio || ""), sanitizeConf);
        }
    }, [author.bio]);

    const ProfileSocials = () => <ProfileSocialsContainer $editMode={editMode} $desktopSideMargin={!userHasProfileComponents || !userHasHighlightOrSubscribe}>
        <div>
            <EditableUIElement
                editMode={editMode}
                text="Add Social Media"
                hasContent={userHasProfileComponents}
                smallPadding={userHasProfileComponents}
                colorVariant={colorVariant}
                onClick={onSocialsEditableClick}
            >
                <V3ProfileSocials
                    colorVariant={colorVariant}
                    author={author}
                    noOpacity={isColorNotWhite}
                />
            </EditableUIElement>
        </div>
        {
            (editMode || userHasHighlightOrSubscribe) && < div >
                <EditableUIElement
                    editMode={editMode}
                    text="Add Contact & Subscribe"
                    hasContent={userHasHighlightOrSubscribe}
                    smallPadding={userHasHighlightOrSubscribe}
                    colorVariant={colorVariant}
                    onClick={onContactEditableClick}
                >
                    <V3ProfileContact
                        colorVariant={colorVariant}
                        author={author}
                        openContactModal={() => setContactModalOpen(true)}
                        noOpacity={isColorNotWhite}
                        openSubscribeModal={openSubscribeModal}
                    />
                </EditableUIElement>
            </div>
        }
    </ProfileSocialsContainer >;

    const showHeadline = editMode || userHasDisplayName;
    const showCertificates = certificates && !!certificates.length;

    const CertificateSVGIcon = () => <V3IconCertificates stroke={colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey48} width={32} height={32} strokeWidth={1} />;

    const url = isMainDomain(requestHostHeader) ? `/${author.slug}` : getCustomDomainURL(host || "");

    const hasAboutPages = !!author.aboutPages.length;

    const pagesWithHref: EditableItem[] = [
        { id: SORTABLE_HOME_IDENTIFIER, label: "Home", hasRules: false, href: !editMode ? url : APPLICATION_ROUTES.PORTFOLIO_APP, hideSettings: true },
        ...author.aboutPages.sort((a, b) => a.order - b.order).map(abtPage => ({
            id: 'id' in abtPage ? abtPage.id!.toString() : abtPage.slug,
            label: abtPage.name,
            hasRules: false,
            href: editMode ? undefined : !isMainDomain(requestHostHeader) ? `${url}${APPLICATION_ROUTES.ABOUT_PAGE.replace("/[author]", "").replace("[aboutPageSlug]", abtPage.slug)}` : `${APPLICATION_ROUTES.ABOUT_PAGE.replace("[author]", author.slug).replace("[aboutPageSlug]", abtPage.slug)}`
        }))
    ];

    const EditableSortableWrapperRenderer = editMode ? React.Fragment : SidebarLayoutShowDesktop;

    return <>
        <V4PHImageContainer
            $useImage={authorUploadedImage}
            $coverColor={color}
            $desktopSrc={!!author.coverUrl.length ? author.coverUrl[0].url : undefined}
            $mobileSrc={author.coverUrl.length > 1 ? author.coverUrl[1].url : undefined}
            $enforceMinHeight={enforceMinHeight}
            $isAboutPage={isAboutPage}
        >
            <V4PHMainWrapper>
                <V4PHContentWrapper>
                    {
                        !editMode && hasAboutPages && <SidebarLayoutShowMobile>
                            <AboutPagesMobileMenu
                                colorVariant={colorVariant}
                                aboutPages={pagesWithHref}
                                authorSlug={author.slug}
                            />
                        </SidebarLayoutShowMobile>
                    }
                    <V4PHTopBar $profileStyle={author.profileStyle} $smallPadding={!hasSomeContent && !editMode} $editMode={editMode} >
                        <div>
                            {
                                !editMode && !hasAboutPages ? <SidebarLayoutShowDesktop>
                                    <OptionalNextLink href={editMode ? undefined : `${APPLICATION_ROUTES.HOMEPAGE}?utm_source=portfolio&utm_medium=logo`}
                                        target="_blank"
                                        useNextRouter={false}
                                        isMainDomain={isMainDomain(requestHostHeader)}
                                    >
                                        <V3AuthoryLogo
                                            color={colorVariant !== V3ProfileHeroColorVariants.light ? theme.v3.colors.ink : "white"}
                                            hideCopy={!!size && !!size.width && size.width < theme.v3.layout.sidebarLayout.mobile}
                                            hasOpacity
                                        />
                                    </OptionalNextLink>
                                </SidebarLayoutShowDesktop> : <EditableSortableWrapperRenderer>
                                    <EditableSortableItems
                                        itemType={EditableSortableType.AboutPages}
                                        scrollCustomColor={author.coverStyle === UserCoverStyle.color ? author.coverColor : undefined}
                                        colorVariant={colorVariant === V3ProfileHeroColorVariants.light ? EditableSortableTheme.Light : EditableSortableTheme.Dark}
                                        its={pagesWithHref}
                                        editMode={editMode}
                                        activeCol={aboutPageId || SORTABLE_HOME_IDENTIFIER}
                                        onItemSelect={(id) => {
                                            if (id === SORTABLE_HOME_IDENTIFIER) return;
                                            onAboutPageSelect(id);
                                        }}
                                        onCollectionStopDrag={onAboutPageStopDrag}
                                        onCollectionSubmitName={onAboutPageSubmitName}
                                        onCollectionRemoveFromProfile={onAboutPageDelete}
                                        renderShortcutAddItem={(_scrolling, onNew, _onOpenChange, simplebarScroll) => {
                                            return <div style={{ padding: 18, display: "flex" }} onClick={() => {
                                                simplebarScroll();
                                                onNew && onNew();
                                            }}>
                                                <V3BlueRoundPlus />
                                            </div>;
                                        }}
                                        renderMainAddItem={(_scrolling, onNew, _onOpenChange, _simplebarScroll, setEditingComponentID, isEditing, itemsLength) => {
                                            return <div onClick={onNew}>
                                                <EditableIt
                                                    editMode
                                                    activeCol={""}
                                                    onSelectDrag={() => { }}
                                                    onItemSelect={() => { }}
                                                    onCollectionSubmitName={onAboutPageSubmitName}
                                                    setEditingComponentID={setEditingComponentID}
                                                    isEditing={isEditing}
                                                    totalItemsNumber={itemsLength}
                                                    addText={"Create page"}
                                                    itemType={EditableSortableType.AboutPages}
                                                    colorVariant={colorVariant === V3ProfileHeroColorVariants.light ? EditableSortableTheme.Light : EditableSortableTheme.Dark}
                                                />
                                            </div>
                                        }}
                                    />
                                </EditableSortableWrapperRenderer>
                            }
                        </div>
                        <div>
                            <SidebarLayoutShowDesktop>
                                <ProfileSocials />
                            </SidebarLayoutShowDesktop>
                        </div>
                    </V4PHTopBar>
                    {
                        isAboutPage ? <AboutPageTitleWrapper $colorVariant={colorVariant}>
                            <V3HeadlineH1>
                                <ProfileContentEditable
                                    $colorVariant={colorVariant}
                                    html={aboutPageTitleRef.current || ""}
                                    onChange={(evt) => {
                                        const sanitizedVal = sanitizeHtml(evt.target.value, sanitizeConf);
                                        if (sanitizedVal === aboutPageTitleRef.current && evt.target.value === aboutPageTitleRef.current) return;
                                        aboutPageTitleRef.current = sanitizedVal === "<br />" ? "" : sanitizedVal;
                                        onAboutPageUpdate();
                                    }}
                                    disabled={!editMode}
                                    placeholder="Enter Title"
                                    $dashOnHover={editMode}
                                    $editMode={editMode}
                                />
                            </V3HeadlineH1>
                        </AboutPageTitleWrapper>
                            : <>
                                {
                                    (hasSomeContent || editMode) && <V4PHContentMainWrapper $profileStyle={author.profileStyle} $viewType={author.defaultView as ProfileView} $isAboutPage={isAboutPage}>
                                        <V4PHAuthorMetaWrapper
                                            $colorVariant={colorVariant}
                                            $profileStyle={author.profileStyle}
                                        >
                                            <div>
                                                {
                                                    (editMode || userHasProfileImage) && <>
                                                        <EditableUIElement
                                                            colorVariant={colorVariant}
                                                            editMode={editMode}
                                                            smallPadding
                                                            customBorderRadius="50%"
                                                            hasContent={userHasProfileImage}
                                                            onClick={onPIEditableClick}
                                                            customNoContent={<V4PHProfileAvatarWrapper $profileStyle={author.profileStyle} $isColorWhite={isColorWhite}>
                                                                <V3IconUpload stroke={"white"} width={24} height={24} />
                                                            </V4PHProfileAvatarWrapper>}
                                                        >
                                                            {
                                                                userHasProfileImage && <V4PHProfileAvatarWrapper
                                                                    $profileStyle={author.profileStyle}
                                                                    $isColorWhite={isColorWhite}
                                                                >
                                                                    <V4PHProfileAvatarImage src={userHasProfileImage ? author.avatarUrl[0].url : undefined} />
                                                                </V4PHProfileAvatarWrapper>
                                                            }
                                                        </EditableUIElement>
                                                    </>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    (showHeadline || showCertificates) && <VerticalSpacing bottom={12}>
                                                        <V4HealineCertificateWrapper $editMode={editMode} $profileStyle={author.profileStyle}  >
                                                            {
                                                                showHeadline && <V3HeadlineH1 as="div" onClick={() => {
                                                                    if (!legacyEditingExperience) return;
                                                                    if (editMode && onPIEditableClick) onPIEditableClick();
                                                                    if (!editMode) setDetailsModalOpen(true);
                                                                }}>
                                                                    {
                                                                        legacyEditingExperience ? <LegacyProfileElem
                                                                            $legacyEditingExperience={legacyEditingExperience}
                                                                            $dashOnHover={editMode && userHasBio}
                                                                            $colorVariant={colorVariant}
                                                                            $editMode={editMode}
                                                                            as="h1"
                                                                        >
                                                                            {author.displayName}
                                                                        </LegacyProfileElem> : <ProfileContentEditable
                                                                            $colorVariant={colorVariant}
                                                                            html={displayNameRef.current}
                                                                            tagName="h1"
                                                                            onChange={(evt) => {
                                                                                const sanitizedVal = sanitizeHtml(evt.target.value, sanitizeConf);
                                                                                if (sanitizedVal === displayNameRef.current && evt.target.value === displayNameRef.current) return;
                                                                                displayNameRef.current = sanitizedVal === "<br />" ? "" : sanitizedVal;
                                                                                onContentUpdate();
                                                                            }}
                                                                            disabled={!editMode || legacyEditingExperience}
                                                                            placeholder="Enter headline"
                                                                            $dashOnHover={editMode && userHasDisplayName}
                                                                            $legacyEditingExperience={legacyEditingExperience}
                                                                            $editMode={editMode}
                                                                        />
                                                                    }
                                                                </V3HeadlineH1>
                                                            }
                                                            {
                                                                showCertificates && <div>
                                                                    {
                                                                        editMode ?
                                                                            <V3Tooltip
                                                                                tooltipMaxWidth={230}
                                                                                tooltipContent={"If visitors click this icon, they’ll see the badges of your certificates."}
                                                                                referenceContent={<div>
                                                                                    <CertificateSVGIcon />
                                                                                </div>}
                                                                            />
                                                                            : <div onClick={() => setcerModalIsOpen(true)}>
                                                                                <CertificateSVGIcon />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </V4HealineCertificateWrapper>
                                                    </VerticalSpacing>
                                                }
                                                {
                                                    (editMode || userHasBio) && <VerticalSpacing bottom={12}>
                                                        <V3Paragraph as="div" onClick={() => {
                                                            if (!legacyEditingExperience) return;
                                                            if (editMode && onPIEditableClick) onPIEditableClick();
                                                            if (!editMode) setDetailsModalOpen(true);
                                                        }}>
                                                            {
                                                                legacyEditingExperience ? <LegacyProfileElem
                                                                    $legacyEditingExperience={legacyEditingExperience}
                                                                    $dashOnHover={editMode && userHasBio}
                                                                    $colorVariant={colorVariant}
                                                                    $editMode={editMode}
                                                                >
                                                                    {shortBio}{showMore && <V3SeeMore>Read more</V3SeeMore>}
                                                                </LegacyProfileElem> : <ProfileContentEditable
                                                                    $colorVariant={colorVariant}
                                                                    html={legacyEditingExperience ? shortBio : bioRef.current}
                                                                    onChange={(evt) => {
                                                                        const sanitizedVal = sanitizeHtml(evt.target.value, sanitizeConf);
                                                                        if (sanitizedVal === bioRef.current && evt.target.value === bioRef.current) return;
                                                                        bioRef.current = sanitizedVal === "<br />" ? "" : sanitizedVal;
                                                                        onContentUpdate();
                                                                    }}
                                                                    disabled={!editMode || legacyEditingExperience}
                                                                    placeholder="Enter subheadline"
                                                                    $dashOnHover={editMode && userHasBio}
                                                                    $legacyEditingExperience={legacyEditingExperience}
                                                                    $editMode={editMode}
                                                                />
                                                            }
                                                        </V3Paragraph>
                                                    </VerticalSpacing>
                                                }
                                            </div>
                                        </V4PHAuthorMetaWrapper>
                                        {
                                            (userHasComponents || editMode) && <MobileSocialsWrapper>
                                                <VerticalSpacing top={userHasProfileComponents || editMode ? 26 : 0}>
                                                    <ProfileSocials />
                                                </VerticalSpacing>
                                            </MobileSocialsWrapper>
                                        }
                                    </V4PHContentMainWrapper>
                                }
                            </>
                    }
                </V4PHContentWrapper>
            </V4PHMainWrapper>
        </V4PHImageContainer >
        {
            certmodalIsOpen && <V3ModalBase
                isOpen={certmodalIsOpen}
                onClose={() => setcerModalIsOpen(false)}
                width="700px"
            >
                <ModalMetadata>
                    {
                        !!author.avatarUrl.length && <VerticalSpacing bottom={isCenteredProfile ? 16 : 10}>
                            <ImageAvatar
                                variant={UserProfileStyle.centered}
                                background={!!author.avatarUrl.length ? author.avatarUrl[0].url : undefined}
                            >
                                <V3ProfileAvatar authorName={getAuthorName(author)} size={"M"} src={!!author.avatarUrl.length ? author.avatarUrl[0].url : undefined} />
                            </ImageAvatar>
                        </VerticalSpacing>
                    }
                    <VerticalSpacing bottom={24}>
                        <V3ProfileName color={V3ProfileHeroColorVariants.dark}>{getAuthorName(author)}</V3ProfileName>
                    </VerticalSpacing>
                    <VerticalSpacing bottom={24}>
                        <V3Paragraph>Authory performs quarterly audits on millions of articles created by thousands of professional content creators. {getDisplayName(author)} has demonstrated exceptional performance with their work.</V3Paragraph>
                    </VerticalSpacing>
                    <CertificatesItemsWrapper>
                        {
                            certificates && certificates.length && certificates.map(certificate => <UnstyledLink
                                key={certificate.uid}
                                target="_blank"
                                href={isMainDomain(requestHostHeader) ? `/${author.slug}/certificate/${certificate.uid}` : `/certificate/${certificate.uid}`}
                            >
                                <CertificateProfileModalItem>
                                    <CertificateSVG user={author} certificate={certificate} maxWidth={170} />
                                </CertificateProfileModalItem>
                            </UnstyledLink>)
                        }
                    </CertificatesItemsWrapper>
                    <VerticalSpacing top={36}>
                        <V3CaptionGrey48>
                            <CertificateModalLink target="_blank" href="https://authory.com/help/en/articles/7733697-what-are-authory-certificates" >What are Authory certificates?</CertificateModalLink>
                        </V3CaptionGrey48>
                    </VerticalSpacing>
                </ModalMetadata>
            </V3ModalBase>
        }
        {
            contactModalOpen && <V3ContactUser
                modelOpen={contactModalOpen}
                onCloseHandler={() => setContactModalOpen(false)}
                userName={getAuthorName(author)}
                onContactUserSubmit={onContactUserSubmit}
            />
        }
        {
            detailsModalOpen && <V3ModalBase
                isOpen={detailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
            >
                <ModalMetadata>
                    {
                        !!author.avatarUrl.length && <VerticalSpacing bottom={isCenteredProfile ? 16 : 10}>
                            <ImageAvatar
                                variant={UserProfileStyle.centered}
                                background={!!author.avatarUrl.length ? author.avatarUrl[0].url : undefined}
                            >
                                <V3ProfileAvatar authorName={getDisplayName(author)} size={"M"} src={!!author.avatarUrl.length ? author.avatarUrl[0].url : undefined} />
                            </ImageAvatar>
                        </VerticalSpacing>
                    }
                    <VerticalSpacing bottom={24}>
                        <V3ProfileName color={V3ProfileHeroColorVariants.dark}>{getDisplayName(author)}</V3ProfileName>
                    </VerticalSpacing>
                    {
                        !!author.bio.length && <VerticalSpacing bottom={24}>
                            {author.bio.split("\n").map((line, index) => <V3Paragraph key={index}>
                                {line}
                            </V3Paragraph>)}
                        </VerticalSpacing>
                    }
                </ModalMetadata>
            </V3ModalBase>
        }
    </>
}