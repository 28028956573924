import styled from "styled-components";
import { media, theme } from "../../theme/theme";

export const PCFRightSide = styled.div<{ $extendedMode: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $extendedMode }) => $extendedMode ? "space-between" : undefined};
    width: 100%;

    > div {
        width: ${({ $extendedMode }) => $extendedMode ? "calc(33.3% - 15px)" : undefined};

        &:nth-child(2) {
            margin-left: 12px;
        }
    }
`;

export const PCFTextWrapper = styled.div<{ $extendedMode: boolean }>`
    width: ${({ $extendedMode }) => !$extendedMode ? "100%" : undefined};
            
    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        width: ${({ $extendedMode }) => !$extendedMode ? "auto" : undefined};
    }
`;