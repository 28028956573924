import { OffsetOptions } from "@floating-ui/react";
import { Collection } from "authory-api-types/dist/types";
import React, { PropsWithChildren } from "react";
import { V3Dropdown } from "../Dropdown"
import { AddToCollectionDropdownPanel } from "../AddToCollectionDropdownPanel";

interface AddCollectionElementProps extends PropsWithChildren {
    onNew?: () => void,
    onCollectionAddToProfile?: (slug: string) => void,
    collections: Collection[],
    isEditing?: boolean
    customOffset?: OffsetOptions,
    scrolling?: boolean,
    onOpenChange?: (open: boolean) => void,
    scrollSimpleBar: () => void,
}

export const CollectionAddItemDropdown = ({ children, onNew, onCollectionAddToProfile, collections, isEditing = false, customOffset = { crossAxis: 2, mainAxis: 5 }, scrolling, onOpenChange, scrollSimpleBar }: AddCollectionElementProps) => {

    if (isEditing || scrolling) return <>{children}</>;

    return <V3Dropdown
        title="Add collection"
        strategy="fixed"
        placement="bottom-end"
        customOffset={customOffset}
        shiftPadding={80}
        onShowChange={onOpenChange}
        renderPopperElement={(_, setActive) => {

            if (!collections.length) {
                onNew && onNew();
                scrollSimpleBar();
                setActive(false);
            }

            return <AddToCollectionDropdownPanel
                collections={collections}
                onCreateCollectionClick={() => {
                    scrollSimpleBar();
                    onNew && onNew();
                }}
                onCollectionClick={(slug) => {
                    scrollSimpleBar();
                    setActive(false);
                    onCollectionAddToProfile && onCollectionAddToProfile(slug);
                }}
            />
        }}
        renderReferenceElement={(active) => {
            const childrenWithProps = React.Children.map(children, child => {
                // Checking isValidElement is the safe way and avoids a
                // typescript error too.
                if (React.isValidElement(child)) {
                    //@ts-ignore
                    return React.cloneElement(child, { addColSettingsActive: active });
                }
                return child;
            });

            return <>{childrenWithProps}</>
        }}
    />
}